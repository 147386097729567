import CryptoJS from "crypto-js";
import Cookies from "js-cookie";
import dateFormat, { masks } from "dateformat";

class HelperClass {
  constructor() {}
  isLogin() {
    if (
      Cookies.get("acn__") == null ||
      Cookies.get("acn__") === undefined ||
      Cookies.get("acn__") === "" ||
      Cookies.get("tkn__") === undefined ||
      Cookies.get("tkn__") === null ||
      Cookies.get("tkn__") === ""
    ) {
      return false;
    } else {
      var bytes = CryptoJS.AES.decrypt(
        Cookies.get("tkn__"),
        process.env.REACT_APP_SIGNATURE
      );
      if (bytes == "") {
        window.location.replace("/login");
        return false;
      }
      var tkn__ = bytes.toString(CryptoJS.enc.Utf8);

      var secByte = CryptoJS.AES.decrypt(
        Cookies.get("acn__"),
        process.env.REACT_APP_SIGNATURE
      );
      if (secByte == "") {
        window.location.replace("/login");
        return false;
      }
      var acn__ = secByte.toString(CryptoJS.enc.Utf8);

      if (tkn__ == "" || acn__ == "") {
        window.location.replace("/login");
        return false;
      } else {
        return true;
      }
    }
  }

  isAdminLogin() {
    if (
      Cookies.get("admin_acc__") == null ||
      Cookies.get("admin_acc__") === undefined ||
      Cookies.get("admin_acc__") === "" ||
      Cookies.get("admin_token__") === undefined ||
      Cookies.get("admin_token__") === null ||
      Cookies.get("admin_token__") === ""
    ) {
      return false;
    } else {
      var bytes = CryptoJS.AES.decrypt(
        Cookies.get("admin_token__"),
        process.env.REACT_APP_SIGNATURE
      );
      if (bytes == "") {
        window.location.replace("/nsaccess");
        return false;
      }
      var admin_token__ = bytes.toString(CryptoJS.enc.Utf8);

      var secByte = CryptoJS.AES.decrypt(
        Cookies.get("admin_acc__"),
        process.env.REACT_APP_SIGNATURE
      );
      if (secByte == "") {
        window.location.replace("/nsaccess");
        return false;
      }
      var admin_acc__ = secByte.toString(CryptoJS.enc.Utf8);

      if (admin_token__ == "" || admin_acc__ == "") {
        window.location.replace("/nsaccess");
        return false;
      } else {
        return true;
      }
    }
  }

  getCompName() {
    if (
      Cookies.get("comp_nme__") == null ||
      Cookies.get("comp_nme__") === undefined ||
      Cookies.get("comp_nme__") === ""
    ) {
      return false;
    } else {
      var secByte = CryptoJS.AES.decrypt(
        Cookies.get("comp_nme__"),
        process.env.REACT_APP_SIGNATURE
      );
      if (secByte == "") {
        return false;
      }
      var comp_nme__ = secByte.toString(CryptoJS.enc.Utf8);

      if (comp_nme__ == "") {
        return false;
      } else {
        return comp_nme__;
      }
    }
  }

  getName() {
    if (
      Cookies.get("name__") == null ||
      Cookies.get("name__") === undefined ||
      Cookies.get("name__") === ""
    ) {
      return false;
    } else {
      var secByte = CryptoJS.AES.decrypt(
        Cookies.get("name__"),
        process.env.REACT_APP_SIGNATURE
      );
      if (secByte == "") {
        return false;
      }
      var name__ = secByte.toString(CryptoJS.enc.Utf8);

      if (name__ == "") {
        return false;
      } else {
        return name__;
      }
    }
  }

  getEmail() {
    if (
      Cookies.get("eml__") == null ||
      Cookies.get("eml__") === undefined ||
      Cookies.get("eml__") === ""
    ) {
      return false;
    } else {
      var secByte = CryptoJS.AES.decrypt(
        Cookies.get("eml__"),
        process.env.REACT_APP_SIGNATURE
      );
      if (secByte == "") {
        return false;
      }
      var eml__ = secByte.toString(CryptoJS.enc.Utf8);

      if (eml__ == "") {
        return false;
      } else {
        return eml__;
      }
    }
  }

  getTitle() {
    if (
      Cookies.get("title__") == null ||
      Cookies.get("title__") === undefined ||
      Cookies.get("title__") === ""
    ) {
      return false;
    } else {
      var secByte = CryptoJS.AES.decrypt(
        Cookies.get("title__"),
        process.env.REACT_APP_SIGNATURE
      );
      if (secByte == "") {
        return false;
      }
      var title__ = secByte.toString(CryptoJS.enc.Utf8);

      if (title__ == "") {
        return false;
      } else {
        return title__;
      }
    }
  }

  getPhone() {
    if (
      Cookies.get("phone__") == null ||
      Cookies.get("phone__") === undefined ||
      Cookies.get("phone__") === ""
    ) {
      return false;
    } else {
      var secByte = CryptoJS.AES.decrypt(
        Cookies.get("phone__"),
        process.env.REACT_APP_SIGNATURE
      );
      if (secByte == "") {
        return false;
      }
      var phone__ = secByte.toString(CryptoJS.enc.Utf8);

      if (phone__ == "") {
        return false;
      } else {
        return phone__;
      }
    }
  }

  getAccount() {
    if (
      Cookies.get("acn__") == null ||
      Cookies.get("acn__") === undefined ||
      Cookies.get("acn__") === ""
    ) {
      return false;
    } else {
      var secByte = CryptoJS.AES.decrypt(
        Cookies.get("acn__"),
        process.env.REACT_APP_SIGNATURE
      );
      if (secByte == "") {
        return false;
      }
      var acn__ = secByte.toString(CryptoJS.enc.Utf8);

      if (acn__ == "") {
        return false;
      } else {
        return acn__;
      }
    }
  }

  getSubUserAccount() {
    if (
      Cookies.get("useracct__") == null ||
      Cookies.get("useracct__") === undefined ||
      Cookies.get("useracct__") === ""
    ) {
      return false;
    } else {
      var secByte = CryptoJS.AES.decrypt(
        Cookies.get("useracct__"),
        process.env.REACT_APP_SIGNATURE
      );
      if (secByte == "") {
        return false;
      }
      var useracct__ = secByte.toString(CryptoJS.enc.Utf8);

      if (useracct__ == "") {
        return false;
      } else {
        return useracct__;
      }
    }
  }

  getDecimalPoint(){
    if (
      Cookies.get("decimalPoint__") == null ||
      Cookies.get("decimalPoint__") === undefined ||
      Cookies.get("decimalPoint__") === ""
    ) {
      return false;
    } else {
      var secByte = CryptoJS.AES.decrypt(
        Cookies.get("decimalPoint__"),
        process.env.REACT_APP_SIGNATURE
      );
      if (secByte == "") {
        return false;
      }
      var decimalPoint__ = secByte.toString(CryptoJS.enc.Utf8);

      if (decimalPoint__ == "") {
        return 2;
      } else {
        return decimalPoint__;
      }
    }
  }

  getSubUserToken() {
    if (
      Cookies.get("usertkn__") == null ||
      Cookies.get("usertkn__") === undefined ||
      Cookies.get("usertkn__") === ""
    ) {
      return false;
    } else {
      var secByte = CryptoJS.AES.decrypt(
        Cookies.get("usertkn__"),
        process.env.REACT_APP_SIGNATURE
      );
      if (secByte == "") {
        return false;
      }
      var usertkn__ = secByte.toString(CryptoJS.enc.Utf8);

      if (usertkn__ == "") {
        return false;
      } else {
        return usertkn__;
      }
    }
  }

  getUserType() {
    if (
      Cookies.get("usertype__") == null ||
      Cookies.get("usertype__") === undefined ||
      Cookies.get("usertype__") === ""
    ) {
      return false;
    } else {
      var secByte = CryptoJS.AES.decrypt(
        Cookies.get("usertype__"),
        process.env.REACT_APP_SIGNATURE
      );
      if (secByte == "") {
        return false;
      }
      var usertype__ = secByte.toString(CryptoJS.enc.Utf8);

      if (usertype__ == "") {
        return false;
      } else {
        return usertype__;
      }
    }
  }

  getToken() {
    if (
      Cookies.get("tkn__") == null ||
      Cookies.get("tkn__") === undefined ||
      Cookies.get("tkn__") === ""
    ) {
      return false;
    } else {
      var secByte = CryptoJS.AES.decrypt(
        Cookies.get("tkn__"),
        process.env.REACT_APP_SIGNATURE
      );
      if (secByte == "") {
        return false;
      }
      var tkn__ = secByte.toString(CryptoJS.enc.Utf8);

      if (tkn__ == "") {
        return false;
      } else {
        return tkn__;
      }
    }
  }

  getAdminType() {
    if (
      Cookies.get("admin_type__") == null ||
      Cookies.get("admin_type__") === undefined ||
      Cookies.get("admin_type__") === ""
    ) {
      return false;
    } else {
      var secByte = CryptoJS.AES.decrypt(
        Cookies.get("admin_type__"),
        process.env.REACT_APP_SIGNATURE
      );
      if (secByte == "") {
        return false;
      }
      var admin_type__ = secByte.toString(CryptoJS.enc.Utf8);

      if (admin_type__ == "") {
        return false;
      } else {
        return admin_type__;
      }
    }
  }

  getAdminName() {
    if (
      Cookies.get("admin_name__") == null ||
      Cookies.get("admin_name__") === undefined ||
      Cookies.get("admin_name__") === ""
    ) {
      return false;
    } else {
      var secByte = CryptoJS.AES.decrypt(
        Cookies.get("admin_name__"),
        process.env.REACT_APP_SIGNATURE
      );
      if (secByte == "") {
        return false;
      }
      var admin_name__ = secByte.toString(CryptoJS.enc.Utf8);

      if (admin_name__ == "") {
        return false;
      } else {
        return admin_name__;
      }
    }
  }

  getAdminFullName() {
    if (
      Cookies.get("full_name__") == null ||
      Cookies.get("full_name__") === undefined ||
      Cookies.get("full_name__") === ""
    ) {
      return false;
    } else {
      var secByte = CryptoJS.AES.decrypt(
        Cookies.get("full_name__"),
        process.env.REACT_APP_SIGNATURE
      );
      if (secByte == "") {
        return false;
      }
      var full_name__ = secByte.toString(CryptoJS.enc.Utf8);

      if (full_name__ == "") {
        return false;
      } else {
        return full_name__;
      }
    }
  }

  getAdminEmail() {
    if (
      Cookies.get("admin_eml__") == null ||
      Cookies.get("admin_eml__") === undefined ||
      Cookies.get("admin_eml__") === ""
    ) {
      return false;
    } else {
      var secByte = CryptoJS.AES.decrypt(
        Cookies.get("admin_eml__"),
        process.env.REACT_APP_SIGNATURE
      );
      if (secByte == "") {
        return false;
      }
      var admin_eml__ = secByte.toString(CryptoJS.enc.Utf8);

      if (admin_eml__ == "") {
        return false;
      } else {
        return admin_eml__;
      }
    }
  }

  getAdminAccount() {
    if (
      Cookies.get("admin_acc__") == null ||
      Cookies.get("admin_acc__") === undefined ||
      Cookies.get("admin_acc__") === ""
    ) {
      return false;
    } else {
      var secByte = CryptoJS.AES.decrypt(
        Cookies.get("admin_acc__"),
        process.env.REACT_APP_SIGNATURE
      );
      if (secByte == "") {
        return false;
      }
      var admin_acc__ = secByte.toString(CryptoJS.enc.Utf8);

      if (admin_acc__ == "") {
        return false;
      } else {
        return admin_acc__;
      }
    }
  }

  getAdminToken() {
    if (
      Cookies.get("admin_token__") == null ||
      Cookies.get("admin_token__") === undefined ||
      Cookies.get("admin_token__") === ""
    ) {
      return false;
    } else {
      var secByte = CryptoJS.AES.decrypt(
        Cookies.get("admin_token__"),
        process.env.REACT_APP_SIGNATURE
      );
      if (secByte == "") {
        return false;
      }
      var admin_token__ = secByte.toString(CryptoJS.enc.Utf8);

      if (admin_token__ == "") {
        return false;
      } else {
        return admin_token__;
      }
    }
  }

  logout() {
    Cookies.remove("comp_nme__");
    Cookies.remove("eml__");
    Cookies.remove("acn__");
    Cookies.remove("tkn__");
    return window.location.replace("/");
  }

  adminlogout() {
    Cookies.remove("admin_name__");
    Cookies.remove("admin_type__");
    Cookies.remove("admin_eml__");
    Cookies.remove("admin_acc__");
    Cookies.remove("admin_token__");
    return window.location.replace("/nsaccess");
  }

  formatUsPhoneNumber(number) {
    const cleaned = ("" + number).replace(/\D/g, "");
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`;
    }
    return number;
  }

  formatDateTime(date) {
    if (date !== "") {
      return dateFormat(date, "mmmm dS, yyyy, h:MM:ss TT");
    }
    return date;
  }
  formatDateOnly(date) {
    if (date && date !== "") {
      return dateFormat(date, "mmmm dS, yyyy");
    }
    return "---";
  }
}

export default HelperClass;
