import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { browserHistory } from "react-router";
import Main from "./components/Reseller/main.js";

import AdminMain from "./components/Admin/admin";

import Test from "./Test.js";

import Error404 from "./components/Errors/404";
import Forgot from "./Pages/Reseller/Forgot/index";
import PasswordSetting from "./Pages/Reseller/PasswordSetting/index";
import SignUpVerification from "./Pages/Reseller/Verification/index";
import UserForms from "./Pages/Reseller/UserForms/index";
import Login from "./Pages/Reseller/Login/index";

import Register from "./Pages/Reseller/Register/index";
import Addservice from "./Pages/Reseller/Services/addservice";
import UserRegister from "./Pages/Reseller/Users/register.js";

import AdminLogin from "./Pages/Admin/Login/admin_login";

function App() {
  console.log("version ", React.version);

  return (
    <div>
      <Router>
        <Switch>
          {/* COSTOMER RESELLER ROUTES */}
          <Route exact path="/" component={(props) => <Login {...props} />} />
          <Route
            exact
            path="/test"
            component={(props) => <Test {...props} />}
          />
          <Route
            exact
            path="/login"
            component={(props) => <Login {...props} />}
          />
          <Route
            exact
            path="/register"
            component={(props) => <Register {...props} />}
          />

          <Route
            exact
            path="/sub_users/register/:signup_hash"
            component={(props) => <UserRegister {...props} />}
          />

          <Route
            exact
            path="/dashboard"
            component={(props) => <Main {...props} view="dashboard" />}
          />
          <Route
            exact
            path="/services"
            component={(props) => <Main {...props} view="services" />}
          />
          <Route
            exact
            path="/services/Addservice"
            component={(props) => <Main {...props} view="Addservice" />}
          />
          <Route
            exact
            path="/services/Editservice/:id"
            component={(props) => <Main {...props} view="Editservice" />}
          />
          <Route
            exact
            path="/services/service_type"
            component={(props) => <Main {...props} view="ServiceType" />}
          />
          <Route
            exact
            path="/services/editServiceType/:id"
            component={(props) => <Main {...props} view="EditServiceType" />}
          />
          <Route
            exact
            path="/services/addServiceType"
            component={(props) => <Main {...props} view="AddServiceType" />}
          />
          <Route
            exact
            path="/services/agreements"
            component={(props) => <Main {...props} view="Productagreements" />}
          />
          <Route
            exact
            path="/services/addagreement"
            component={(props) => (
              <Main {...props} view="AddProductagreements" />
            )}
          />

          <Route
            exact
            path="/services/editagreement/:id/"
            component={(props) => (
              <Main {...props} view="EditProductagreements" />
            )}
          />
          <Route
            exact
            path="/clients/Addclient"
            component={(props) => <Main {...props} view="Addclient" />}
          />
          <Route
            exact
            path="/services/Addratedeck"
            component={(props) => <Main {...props} view="Addratedeck" />}
          />
          <Route
            exact
            path="/services/Addbundle"
            component={(props) => <Main {...props} view="Addbundle" />}
          />
          <Route
            exact
            path="/agent_roles"
            component={(props) => <Main {...props} view="agent_roles" />}
          />

          <Route
            exact
            path="/agent_roles/add_agent_role"
            component={(props) => <Main {...props} view="add_agent_role" />}
          />

          <Route
            exact
            path="/agent_roles/edit_agent_role/:id/"
            component={(props) => <Main {...props} view="edit_agent_role" />}
          />

          <Route
            exact
            path="/agent_roles/edit_agent_subrole/:id/"
            component={(props) => <Main {...props} view="edit_agent_subrole" />}
          />

          <Route
            exact
            path="/agent_roles/add_agent_subrole"
            component={(props) => <Main {...props} view="add_agent_subrole" />}
          />
          {/* CLIENT ROLES */}
          <Route
            exact
            path="/client_roles"
            component={(props) => <Main {...props} view="client_roles" />}
          />

          <Route
            exact
            path="/client_roles/add_client_role"
            component={(props) => <Main {...props} view="add_client_role" />}
          />

          <Route
            exact
            path="/client_roles/add_client_subrole"
            component={(props) => <Main {...props} view="add_client_subrole" />}
          />

          <Route
            exact
            path="/client_roles/edit_client_role/:id/"
            component={(props) => <Main {...props} view="edit_client_role" />}
          />

          <Route
            exact
            path="/client_roles/edit_client_subrole/:id/"
            component={(props) => (
              <Main {...props} view="edit_client_subrole" />
            )}
          />
          <Route
            exact
            path="/forms"
            component={(props) => <Main {...props} view="forms" />}
          />
          <Route
            exact
            path="/forms/add_form"
            component={(props) => <Main {...props} view="add_form" />}
          />
          <Route
            exact
            path="/forms/edit_form/:id"
            component={(props) => <Main {...props} view="edit_form" />}
          />
          <Route
            exact
            path="/forms/view_form/:id"
            component={(props) => <Main {...props} view="view_form" />}
          />
          <Route
            exact
            path="/forms/view_submission/:form_token"
            component={(props) => <Main {...props} view="view_submission" />}
          />
          <Route
            exact
            path="/forms/view_client_submission/:form_token"
            component={(props) => <Main {...props} view="view_client_submission" />}
          />
          <Route
            exact
            path="/forms/view_agent_submission/:form_token"
            component={(props) => <Main {...props} view="view_agent_submission" />}
          />
          
          <Route
            exact
            path="/client_permissions"
            component={(props) => <Main {...props} view="ClientPermissons" />}
          />
          <Route
            exact
            path="/cms"
            component={(props) => <Main {...props} view="cms" />}
          />
          <Route
            exact
            path="/cms/Pageslist"
            component={(props) => <Main {...props} view="Pageslist" />}
          />
          <Route
            exact
            path="/cms/Addpages"
            component={(props) => <Main {...props} view="Addpages" />}
          />
          <Route
            exact
            path="/cms/Editpage/:id"
            component={(props) => <Main {...props} view="Editpage" />}
          />
          <Route
            exact
            path="/agreements/Pdfdownload/:id"
            component={(props) => <Main {...props} view="Pdfdownload" />}
          />
          <Route
            exact
            path="/cms/Menuslist"
            component={(props) => <Main {...props} view="Menuslist" />}
          />
          <Route
            exact
            path="/cms/Addmenus"
            component={(props) => <Main {...props} view="Addmenus" />}
          />
          <Route
            exact
            path="/cms/Editmenu/:id"
            component={(props) => <Main {...props} view="Editmenu" />}
          />
          <Route
            exact
            path="/cms/Headerlist"
            component={(props) => <Main {...props} view="Headerlist" />}
          />
          <Route
            exact
            path="/cms/Addheader"
            component={(props) => <Main {...props} view="Addheader" />}
          />
          <Route
            exact
            path="/cms/Websettings"
            component={(props) => <Main {...props} view="Websettings" />}
          />
          <Route
            exact
            path="/themes/Editor"
            component={(props) => <Main {...props} view="Editor" />}
          />
          <Route
            exact
            path="/themes"
            component={(props) => <Main {...props} view="themes" />}
          />
          <Route
            exact
            path="/domains"
            component={(props) => <Main {...props} view="domains" />}
          />
          <Route
            exact
            path="/domains/Adddomains"
            component={(props) => <Main {...props} view="Adddomains" />}
          />
          <Route
            exact
            path="/clients"
            component={(props) => <Main {...props} view="clients" />}
          />

          <Route
            exact
            path="/clients/subclients/:id"
            component={(props) => <Main {...props} view="subclients" />}
          />
          <Route
            exact
            path="/viewClient/:client_accountno/:tab_type?"
            component={(props) => <Main {...props} view="viewClient" />}
          />
          <Route
            exact
            path="/clients/subclientrights/:sub_id"
            component={(props) => <Main {...props} view="subclientrights" />}
          />

          <Route
            exact
            path="/sub_users"
            component={(props) => <Main {...props} view="sub_users" />}
          />
          <Route
            exact
            path="/sub_users/adduser"
            component={(props) => <Main {...props} view="adduser" />}
          />
          <Route
            exact
            path="/sub_users/user_roles/:id"
            component={(props) => <Main {...props} view="user_roles" />}
          />
          <Route
            exact
            path="/analytics"
            component={(props) => <Main {...props} view="analytics" />}
          />
          <Route
            exact
            path="/reports/:api_url/"
            component={(props) => <Main {...props} view="reports" />}
          />
          <Route
            exact
            path="/agreements"
            component={(props) => <Main {...props} view="agreements" />}
          />
          <Route
            exact
            path="/signed/agentagreements"
            component={(props) => (
              <Main {...props} view="SignedAgentAgreements" />
            )}
          />

          <Route
            exact
            path="/signed/clientagreements"
            component={(props) => (
              <Main {...props} view="SignedClientAgreements" />
            )}
          />

          <Route
            exact
            path="/signed/creditapp"
            component={(props) => <Main {...props} view="CreditApp" />}
          />
          <Route
            exact
            path="/package_agreements"
            component={(props) => <Main {...props} view="package_agreements" />}
          />
          <Route
            exact
            path="/package_agreements/add_package_agreement"
            component={(props) => (
              <Main {...props} view="add_package_agreement" />
            )}
          />
          <Route
            exact
            path="/package_agreements/edit_package_agreement/:id/"
            component={(props) => (
              <Main {...props} view="edit_package_agreement" />
            )}
          />
          <Route
            exact
            path="/agents"
            component={(props) => <Main {...props} view="agents" />}
          />

          <Route
            exact
            path="/agents/Addagent"
            component={(props) => <Main {...props} view="Addagent" />}
          />
          <Route
            exact
            path="/notifications/:notification_type?/"
            component={(props) => <Main {...props} view="notifications" />}
          />
          <Route
            exact
            path="/agents/agreements"
            component={(props) => <Main {...props} view="Agentagreements" />}
          />
          <Route
            exact
            path="/agents/addagreement"
            component={(props) => <Main {...props} view="AddAgentagreements" />}
          />
          <Route
            exact
            path="/agents/editagreement/:id/"
            component={(props) => (
              <Main {...props} view="EditAgentagreements" />
            )}
          />

          <Route
            exact
            path="/clients/agreements"
            component={(props) => <Main {...props} view="Clientagreements" />}
          />
          <Route
            exact
            path="/clients/addagreement"
            component={(props) => (
              <Main {...props} view="AddClientagreements" />
            )}
          />
          <Route
            exact
            path="/clients/addclientagreement"
            component={(props) => <Main {...props} view="AddClientagreement" />}
          />
          <Route
            exact
            path="/clients/editagreement/:id/"
            component={(props) => (
              <Main {...props} view="EditClientagreements" />
            )}
          />

          <Route
            exact
            path="/upgrades/:settings_type?/"
            component={(props) => <Main {...props} view="upgrades" />}
          />
          <Route
            exact
            path="/agent_permissions"
            component={(props) => <Main {...props} view="AgentPermissons" />}
          />
          <Route
            exact
            path="/Integrations"
            component={(props) => <Main {...props} view="Integrations" />}
          />
          <Route
            exact
            path="/QuestionsLibrary/groups"
            component={(props) => <Main {...props} view="RfqQuestionsGroups" />}
          />
          <Route
            exact
            path="/QuestionsLibrary/add_group"
            component={(props) => (
              <Main {...props} view="RfqQuestionsAddGroup" />
            )}
          />
          <Route
            exact
            path="/QuestionsLibrary/edit_group/:id"
            component={(props) => (
              <Main {...props} view="RfqQuestionsEditGroup" />
            )}
          />
          <Route
            exact
            path="/QuestionsLibrary"
            component={(props) => <Main {...props} view="QuestionsLibrary" />}
          />
          <Route
            exact
            path="/AddQuestionsLibrary"
            component={(props) => (
              <Main {...props} view="AddQuestionsLibrary" />
            )}
          />
          <Route
            exact
            path="/EditQuestionsLibrary/:question_id"
            component={(props) => (
              <Main {...props} view="EditQuestionsLibrary" />
            )}
          />
          <Route
            exact
            path="/Quotations/:client_accountno?"
            component={(props) => <Main {...props} view="Quotations" />}
          />
          <Route
            exact
            path="/SalesOrders/:client_accountno?"
            component={(props) => <Main {...props} view="SalesOrders" />}
          />
          <Route
            exact
            path="/ViewSalesOrder/:orderno"
            component={(props) => <Main {...props} view="ViewSalesOrder" />}
          />
          <Route
            exact
            path="/PurchaseOrders/:vendor_accountno?"
            component={(props) => <Main {...props} view="PurchaseOrders" />}
          />
          <Route
            exact
            path="/Vendors"
            component={(props) => <Main {...props} view="Vendors" />}
          />
          <Route
            exact
            path="/Rfqs/:client_accountno?"
            component={(props) => <Main {...props} view="Rfqs" />}
          />

          <Route
            exact
            path="/EditQuotations/:client_accountno/:quote_accountno"
            component={(props) => <Main {...props} view="EditQuotations" />}
          />
          <Route
            exact
            path="/EditQuotations/:client_accountno/:quote_accountno/:rfq_accountno?"
            component={(props) => <Main {...props} view="EditQuotations" />}
          />
          <Route
            exact
            path="/QuotationAgreements"
            component={(props) => (
              <Main {...props} view="QuotationAgreements" />
            )}
          />
          <Route
            exact
            path="/QuotationAgreements/addagreement"
            component={(props) => (
              <Main {...props} view="AddQuotationAgreements" />
            )}
          />
          <Route
            exact
            path="/QuotationAgreements/editagreement/:id/"
            component={(props) => (
              <Main {...props} view="EditQuotationAgreements" />
            )}
          />
          <Route
            exact
            path="/EditSalesOrder/:client_accountno/:orderno"
            component={(props) => <Main {...props} view="EditSalesOrder" />}
          />
          <Route
            exact
            path="/EditSalesOrder/:client_accountno/:orderno/:quote_accountno?"
            component={(props) => <Main {...props} view="EditSalesOrder" />}
          />
          <Route
            exact
            path="/Collaterals"
            component={(props) => <Main {...props} view="Collaterals" />}
          />
          <Route
            exact
            path="/Collaterals/groups"
            component={(props) => <Main {...props} view="CollateralsGroups" />}
          />

          <Route
            exact
            path="/Collaterals/addGroup"
            component={(props) => (
              <Main {...props} view="CollateralsAddGroup" />
            )}
          />

          <Route
            exact
            path="/Collaterals/edit_group/:id"
            component={(props) => (
              <Main {...props} view="CollateralsEditGroup" />
            )}
          />

          <Route
            exact
            path="/CannedEmails"
            component={(props) => <Main {...props} view="CannedEmails" />}
          />
          <Route
            exact
            path="/CannedEmails/editemail/:id/"
            component={(props) => <Main {...props} view="Editemail" />}
          />

          <Route
            exact
            path="/CannedEmails/edit_agent_email/:id/"
            component={(props) => <Main {...props} view="EditAgentemail" />}
          />
          <Route
            exact
            path="/CannedEmails/previewEmail/:id/"
            component={(props) => <Main {...props} view="PreviewEmail" />}
          />

          <Route
            exact
            path="/CannedEmails/previewAgentEmail/:id/"
            component={(props) => <Main {...props} view="PreviewAgentEmail" />}
          />

          <Route
            exact
            path="/backoffice"
            component={(props) => <Main {...props} view="back office" />}
          />
          <Route
            exact
            path="/msa_signing"
            component={(props) => <Main {...props} view="msa_signing" />}
          />
          <Route
            exact
            path="/verification/:token_code/"
            component={SignUpVerification}
          />
          <Route exact path="/user_forms/:form_token/" component={UserForms} />

          <Route exact path="/forgot/" component={Forgot} />
          <Route
            exact
            path="/password_setting/:token_code/"
            component={PasswordSetting}
          />

          {/* END CUSTOMER RESELLER ROUTES */}

          {/* START ADMIN ROUTES */}
          <Route
            exact
            path="/nsaccess"
            component={(props) => <AdminLogin {...props} />}
          />
          <Route
            exact
            path="/admin_dashboard"
            component={(props) => (
              <AdminMain {...props} view="admin_dashboard" />
            )}
          />
          <Route
            exact
            path="/clients_admin"
            component={(props) => <AdminMain {...props} view="clients_admin" />}
          />
          <Route
            exact
            path="/admin_services"
            component={(props) => (
              <AdminMain {...props} view="admin_services" />
            )}
          />
          <Route
            exact
            path="/admin_agreements"
            component={(props) => (
              <AdminMain {...props} view="admin_agreements" />
            )}
          />

          <Route
            exact
            path="/admin_agreements/addagreement"
            component={(props) => <AdminMain {...props} view="addagreement" />}
          />
          <Route
            exact
            path="/admin_agreements/editagreement/:id"
            component={(props) => <AdminMain {...props} view="editagreement" />}
          />
          <Route
            exact
            path="/reports_admin/:api_url/"
            component={(props) => <AdminMain {...props} view="reports_admin" />}
          />
          <Route
            exact
            path="/admin_downloads/:id"
            component={(props) => (
              <AdminMain {...props} view="admin_downloads" />
            )}
          />
          <Route
            exact
            path="/admin_subpoena"
            component={(props) => (
              <AdminMain {...props} view="admin_subpoena" />
            )}
          />
          <Route
            exact
            path="/admin_subpoena/download_subpoena/:filename"
            component={(props) => (
              <AdminMain {...props} view="download_subpoena" />
            )}
          />
          {/* END ADMIN ROUTES */}

          <Route component={Error404} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
