import { React } from "react";
import { Component } from "react";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import CreateIcon from "@material-ui/icons/Create";
import { connect } from "react-redux";

import { addSettings } from "../../../actions";
import {
  listServicesTypes,
  archiveServiceTypeAction,
  setDefault,
} from "./../../../config/api_calls";

import $ from "jquery";
import DataTableExtended from "../../../components/Tables/DataTableExtended";
import { tableLoader } from "../../../components/ContentLoaders/table_loader";
import MsaWarning from "../ExtraComponents/MsaWarning";
import SettingsError from "../ExtraComponents/SettingsError";
import PageTitle from "../ExtraComponents/PageTitle";
import HelperClass from "./../../../config/helperClass";
import { THEME_TEXT_COLOR, THEME_COLOR } from "./../../../config/config";
import MutextField from "@material-ui/core/TextField";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";

import Tooltip from "@material-ui/core/Tooltip";
import dateFormat, { masks } from "dateformat";

const auth = new HelperClass();

class ServiceType extends Component {
  constructor() {
    super();

    this.state = {
      errorMessage: "",
      serviceId: "",
      successMessage: "",
      successMessaged: "",
      errorMessaged: "",
      waitMessage: "",
      mainError: "",
      tableLoader: true,
      tableLoaderBundle: true,
      disabled: false,
      main_type: "",
      rate_deck_file: "",
      assigned_agreements: [],
      list_agreements: [],
      package_status: "",
      service_type_id: "",
      archive: "",
      isProcessing: false,
      columnServices: [
        
        { name: "Title" },
        {
          name: "Status",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.activeStatus(value, tableMeta)}</div>;
            },
          },
        },
        {
          name: "Archive",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.archiveStatus(value, tableMeta)}</div>;
            },
          },
        },
        { name: "ID", options: { display: "false" } },
        {
          name: "Default",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.defaultStatus(value, tableMeta)}</div>;
            },
          },
        },
        {
          name: "Actions",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return (
                <div>
                  <div
                    className="nk-tb-col nk-tb-col-tools"
                    style={{ padding: "0px" }}
                  >
                    <ul className="nk-tb-actions gx-1">
                      <li>
                        <div className="drodown">
                          <a
                            style={{ cursor: "pointer" }}
                            title="Other Actions"
                            className="dropdown-toggle btn btn-icon  btn-outline-light"
                            data-toggle="dropdown"
                          >
                            <em className="icon ni ni-more-h"></em>
                          </a>
                          <div className="dropdown-menu dropdown-menu-right">
                            <ul className="link-list-opt no-bdr">
                              <li>
                                <Tooltip
                                  title="Edit Service Type"
                                  placement="top"
                                >
                                  <a
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                      this.addMenuHistory(
                                        "/services/editservicetype/" +
                                          tableMeta.rowData[3]
                                      )
                                    }
                                    disabled={
                                      this.props.is_msa_signed === "No" ||
                                      this.props.defaultSettings === "missing"
                                        ? true
                                        : false
                                    }
                                  >
                                    <em className="icon ni ni-edit"></em>
                                    <span> Edit  </span>
                                  </a>
                                </Tooltip>
                              </li>
                              {tableMeta.rowData[4] === 0 ? (
                                <li>
                                  <Tooltip
                                    title="Set as Default Value"
                                    placement="top"
                                  >
                                    <a
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        this.defaultAlert(tableMeta.rowData[3])
                                      }
                                    >
                                      <em className="icon ni ni-star"></em>
                                      <span> Default Value </span>
                                    </a>
                                  </Tooltip>
                                </li>
                              ) : (
                                <></>
                              )}
                              <li>
                                <Tooltip
                                  title="Delete Service Type"
                                  placement="top"
                                >
                                  <a
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                      this.ArchiveServiceType(value, tableMeta)
                                    }
                                    disabled={
                                      this.props.is_msa_signed === "No" ||
                                      this.props.defaultSettings === "missing"
                                        ? true
                                        : false
                                    }
                                  >
                                    {tableMeta.rowData[2] === 0 ? (
                                      <>
                                        <em class="icon ni ni-archive"></em>
                                        <span> Archive  </span>
                                      </>
                                    ) : (
                                      <>
                                        <em class="icon ni ni-unarchive"></em>
                                        <span> Unarchive  </span>
                                      </>
                                    )}
                                  </a>
                                </Tooltip>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              );
            },
          },
        },
      ],
    };
  }

  activeStatus = (value, tableMeta) => {
    let status = tableMeta.rowData[1];
    if (status === 1) {
      return <span className="badge badge-outline-success">Active</span>;
    } else {
      return <span className="badge badge-outline-danger">Inactive</span>;
    }
  };

  archiveStatus = (value, tableMeta) => {
    let status = tableMeta.rowData[2];
    if (status === 0) {
      return <span className="badge badge-outline-success">NO</span>;
    } else {
      return <span className="badge badge-outline-danger">Yes</span>;
    }
  };

  viewBtns = () => {
    if (
      this.props.is_msa_signed === "No" ||
      this.props.defaultSettings === "missing"
    ) {
      return <VisibilityOffIcon />;
    } else {
      return <VisibilityIcon style={{ cursor: "pointer" }} />;
    }
  };

  ArchiveServiceType = (value, tableMeta) => {
    let service_type_id = tableMeta.rowData[3];
    let archive = tableMeta.rowData[2];
    this.setState({
      service_type_id: service_type_id,
      archive: archive,
      errorMessaged: "",
      successMessaged: "",
      disabled: false,
    });
    window.$("#modalDeleteAlert").modal("show");
  };

  modalHideDel = () => {
    window.$("#modalDeleteAlert").modal("hide");
  };

  actionArchiveServiceType = async () => {
    let service_type_id = this.state.service_type_id;
    let archive = this.state.archive;
    this.setState({
      disabled: true,
    });
    const agreementResponce = await archiveServiceTypeAction(
      auth.getAccount(),
      auth.getToken(),
      service_type_id,
      archive
    );
    console.log("status: ", agreementResponce.data);
    if (
      agreementResponce.data.status === 403 ||
      agreementResponce.data.errors === "authentication missing" ||
      agreementResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      agreementResponce.data.status === 404 &&
      agreementResponce.data.message === "fail"
    ) {
      // window.location.replace("/error");
      this.setState({
        errorMessaged: agreementResponce.data.message,
        successMessaged: "",
        disabled: false,
      });
      //$("#btnloader").hide();
    } else if (
      agreementResponce.data.status === 200 &&
      agreementResponce.data.message === "success"
    ) {
      let archive = this.state.archive;
      var message = "";
      if (archive == 1) {
        message = "Service Type unarchived successfully.";
      } else {
        message = "Service Type archived successfully.";
      }
      this.setState({
        errorMessaged: "",
        successMessaged: message,
        disabled: false,
      });
      
      setTimeout(function () {
        window.$("#modalDeleteAlert").modal("hide");
        window.location.reload();
      }, 3000);
    } else {
      this.setState({
        errorMessaged: agreementResponce.data.message,
        successMessaged: "",
        disabled: false,
      });
    }
  };

  format_date = (value, tableMeta) => {
    let date = value;
    return dateFormat(date, "mmmm dS, yyyy, h:MM:ss TT");
  };

  async componentDidMount() {
    const servicesResponce = await listServicesTypes(
      auth.getAccount(),
      auth.getToken()
    );
    console.log("servicesResponce.data.data: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        tableData: [],
        mainError: "There is some error while getting the services Types",
      });
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        tableData: servicesResponce.data.data,
        tableLoader: false,
      });
    } else {
      this.setState({
        tableData: [],
        mainError: "There is some error while getting the services",
      });
    }
  }

  defaultAlert = (value) => {
    let id = value;
    this.setState({
      default_id: id,
    });
    window.$("#modalDefaultAlert").modal("show");
  };

  setDefault = async () => {
    let service_id = this.state.default_id;
    this.setState({ isProcessing: true });
    const serviceResponce = await setDefault(
      auth.getAccount(),
      auth.getToken(),
      service_id
    );

    console.log("defaultServiceResponse: ", serviceResponce.data);
    //$("#btnloader").show();
    if (
      serviceResponce.data.status === 403 ||
      serviceResponce.data.errors === "authentication missing" ||
      serviceResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      serviceResponce.data.status === 404 &&
      serviceResponce.data.message === "fail" &&
      serviceResponce.data.note !== ""
    ) {
      // window.location.replace("/error");
      this.setState({
        errorMessage: serviceResponce.data.note,
        successMessaged: "",
        disabled: false,
      });
      //$("#btnloader").hide();
    } else if (
      serviceResponce.data.status === 200 &&
      serviceResponce.data.message === "success"
    ) {
      this.setState({
        errorMessage: "",
        successMessage: "Service set as default successfully.",
        disabled: false,
      });

      setTimeout(function () {
        window.location.reload();
        window.$("#modalDeleteAlert").modal("hide");
      }, 3000);
      //$("#btnloader").hide();
    } else {
      this.setState({
        errorMessage: serviceResponce.data.message,
        successMessaged: "",
        disabled: false,
      });
      //$("#btnloader").hide();
      //window.location.replace("/error");
    }
  };

  defaultStatus = (value, tableMeta) => {
    let status = tableMeta.rowData[4];
    if (status === 1) {
      return <span className="badge badge-outline-success">Yes</span>;
    } else {
      return <span className="badge badge-outline-danger">No</span>;
    }
  };

  modalHideDefault = () => {
    window.$("#modalDefaultAlert").modal("hide");
  };

  addMenuHistory = (link) => {
    this.props.props.history.push(link);
  };

  render() {
    return (
      <div className="nk-content " id="Services_Block">
        <div className="container-fluid">
          {this.props.defaultSettings === "missing" &&
          this.props.from !== "dashboard" ? (
            <SettingsError />
          ) : null}
          {/* MSA WARNING  START*/}
          {this.props.is_msa_signed === "No" &&
          this.props.from !== "dashboard" ? (
            <MsaWarning />
          ) : null}

          {/* MSA WARNING END */}

          <div className="nk-content-inner">
            <div className="nk-content-body">
              {/* PAGE TITLE START */}
              <div className="nk-block-head nk-block-head-sm">
                <div className="nk-block-between">
                  <div className="nk-block-head-content">
                    <h3 className="nk-block-title page-title">
                      <PageTitle
                        name="Service Types"
                        icon="icon ni ni-card-view"
                      />
                    </h3>
                  </div>
                  <div className="nk-block-head-content" id="HeadContentAgent">
                    <div className="toggle-wrap nk-block-tools-toggle">
                      <a
                        href="#"
                        className="btn btn-icon btn-trigger toggle-expand mr-n1"
                        data-target="pageMenu"
                      >
                        <em className="icon ni ni-more-v"></em>
                      </a>
                      <div
                        className="toggle-expand-content"
                        data-content="pageMenu"
                      >
                        <ul className="nk-block-tools g-3">
                          <li className="nk-block-tools-opt">
                            <a
                              style={{ cursor: "pointer", color: "#fff" }}
                              onClick={() =>
                                this.addMenuHistory("/services/addServiceType")
                              }
                              className="btn btn-primary"
                            >
                              <em className="icon ni ni-plus"></em> Add New
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* PAGE TITLE END */}
              <div className="nk-block">
                <div className="row g-gs">
                  {/* Table 5th Col Start */}
                  <div className="col-xxl-12">
                    <div className="nk-block nk-block-lg">
                      <div className="card card-bordered">
                        <div className="card-inner">
                          {this.state.mainError !== "" ? (
                            <div className="example-alert">
                              <div className="alert alert-pro alert-danger">
                                <div className="alert-text">
                                  <h4>Error</h4>
                                  <p>{this.state.mainError}</p>
                                </div>
                              </div>
                            </div>
                          ) : null}
                          {/* START DATATABLE */}
                          {this.state.tableLoader === true ? (
                            tableLoader()
                          ) : (
                            <DataTableExtended
                              columns={this.state.columnServices}
                              tableData={this.state.tableData}
                              title=""
                            />
                          )}

                          {/* END TABLE */}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Table 5th Col End */}
                </div>
              </div>
              {/* START DELETE MODAL  */}
              <div className="modal fade" tabIndex="-1" id="modalDeleteAlert">
                <div className="modal-dialog" role="document">
                  <div className="modal-content">
                    <a
                      style={{ cursor: "pointer" }}
                      className="close"
                      onClick={() => {
                        this.modalHideDel();
                      }}
                    >
                      <em className="icon ni ni-cross"></em>
                    </a>
                    <div className="modal-body modal-body-lg text-center">
                      <div className="nk-modal">
                        <em className="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-trash bg-success"></em>
                        {this.state.archive === 0 ? (
                          <h4 className="nk-modal-title">
                            Archive Service Type?
                          </h4>
                        ) : (
                          <h4 className="nk-modal-title">
                            Unarchive Service Type?
                          </h4>
                        )}

                        <div className="nk-modal-text">
                          {this.state.errorMessaged !== "" ? (
                            <div
                              className="example-alert"
                              style={{ marginBottom: "15px" }}
                            >
                              <div className="alert alert-pro alert-danger">
                                <div className="alert-text">
                                  <h4>Error</h4>
                                  <p>{this.state.errorMessaged}</p>
                                </div>
                              </div>
                            </div>
                          ) : null}
                          {this.state.successMessaged !== "" ? (
                            <div
                              className="example-alert"
                              style={{ marginBottom: "15px" }}
                            >
                              <div className="alert alert-pro alert-success">
                                <div className="alert-text">
                                  <h4>Success</h4>
                                  <p>{this.state.successMessaged}</p>
                                </div>
                              </div>
                            </div>
                          ) : null}
                          {this.state.archive === 0 ? (
                            <div className="caption-text">
                              Are you sure you want to Archive this Service
                              Type?
                            </div>
                          ) : (
                            <div className="caption-text">
                              Are you sure you want to Unarchive this Service
                              Type?
                            </div>
                          )}
                        </div>
                        <div className="nk-modal-action">
                          <a
                            style={{ cursor: "pointer" }}
                            className="btn btn-lg btn-mw btn-primary"
                            onClick={() => {
                              this.modalHideDel();
                            }}
                          >
                            CANCEL
                          </a>
                          {"  "}
                          <a
                            style={{ cursor: "pointer" }}
                            className={
                              this.state.archive === 0
                                ? "btn btn-lg btn-mw btn-danger"
                                : "btn btn-lg btn-mw btn-success"
                            }
                            onClick={() => {
                              this.actionArchiveServiceType();
                            }}
                            disabled={this.state.disabled ? true : false}
                          >
                            {this.state.archive === 0 ? "Archive" : "Unarchive"}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* END DELETE MODAL  */}
              {/* MODAL DEFAULT SERVICES START */}
              <div className="modal fade" tabIndex="-1" id="modalDefaultAlert">
                <div className="modal-dialog" role="document">
                  <div className="modal-content">
                    <a
                      style={{ cursor: "pointer" }}
                      className="close"
                      onClick={() => {
                        this.modalHideDefault();
                      }}
                    >
                      <em className="icon ni ni-cross"></em>
                    </a>
                    <div className="modal-body modal-body-lg text-center">
                      <div className="nk-modal">
                        <em className="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-trash bg-success"></em>
                        <h4 className="nk-modal-title">
                          Make Default Service?
                        </h4>
                        {this.state.errorMessage !== "" ? (
                          <div
                            className="example-alert"
                            style={{ marginBottom: "15px" }}
                          >
                            <div className="alert alert-pro alert-danger">
                              <div className="alert-text">
                                <h4>Error</h4>
                                <p>{this.state.errorMessage}</p>
                              </div>
                            </div>
                          </div>
                        ) : null}
                        {this.state.successMessage !== "" ? (
                          <div
                            className="example-alert"
                            style={{ marginBottom: "15px" }}
                          >
                            <div className="alert alert-pro alert-success">
                              <div className="alert-text">
                                <h4>Success</h4>
                                <p>{this.state.successMessage}</p>
                              </div>
                            </div>
                          </div>
                        ) : null}
                        <div className="nk-modal-text">
                          <div className="caption-text">
                            Are you sure you want make this service default?
                          </div>
                        </div>
                        <div className="nk-modal-action">
                          <button
                            style={{
                              cursor:
                                this.state.disabled || this.state.isProcessing
                                  ? "not-allowed"
                                  : "pointer",
                            }}
                            disabled={
                              this.state.disabled || this.state.isProcessing
                            }
                            className="btn btn-lg btn-mw btn-danger"
                            onClick={() => {
                              this.modalHideDefault();
                            }}
                          >
                            CANCEL
                          </button>
                          {"  "}
                          <button
                            style={{
                              cursor:
                                this.state.disabled || this.state.isProcessing
                                  ? "not-allowed"
                                  : "pointer",
                            }}
                            className="btn btn-lg btn-mw btn-primary"
                            onClick={() => {
                              this.setDefault(this.state.default_id);
                            }}
                            disabled={
                              this.state.disabled || this.state.isProcessing
                            }
                          >
                            <span>MAKE DEFAULT</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* MODAL DEFAULT SERVICES END */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    defaultSettings: state.settings,
    is_msa_signed: state.is_msa_signed,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addSettings: (payload) => dispatch(addSettings(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ServiceType);
